.input {
  display: inline-block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-family: "Roboto";
  border: none;
  border-radius: 25px;
  background-color: var(--white);
  color: var(--dark-grey);
  padding: 0 215px 0 25px;
}

.button {
  background-color: var(--light-purple);
  height: 50px;
  width: 215px;
  font-family: "Roboto";
  color: var(--white);
  border: none;
  border-radius: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

.button:not(:disabled) {
  cursor: pointer;
}

.button:disabled {
  background-color: darkgray;
}

.wrapper {
  display: flex;
  position: relative;
  margin: 50px 100px;
}

.input:focus,
.button:focus {
  outline: none;
}

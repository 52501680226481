.card {
  display: grid;
  grid-template-columns: 170px auto;
  grid-template-rows: 100%;
  width: 390px;
  height: 225px;
  background-color: var(--white);
  text-align: left;
  border: none;
  border-radius: 25px;
  box-shadow: 0px 4px 4px rgba(121, 118, 118, 0.25);
  align-items: center;
  margin: 30px auto;
  color: var(--dark-grey);
}

.card h2 {
  margin-top: 25px;
  margin-bottom: 10px;
}

.cardImage {
  display: block;
  width: 125px;
  height: 170px;
  margin: 0 25px;
  border-radius: 5px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-self: start;
  justify-content: flex-start;
  height: 100%;
}

.button {
  display: block;
  width: 160px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  font-weight: 500;
  font-family: "Roboto";
  border: none;
  border-radius: 10px;
  background-color: var(--dark-purple);
  color: var(--white);
  margin-top: auto;
  margin-bottom: 25px;
}

.button:hover {
  cursor: pointer;
  background-color: var(--light-purple);
}

.button:active,
.button:focus {
  outline: none;
}

.sidebar {
  font-family: "Roboto";
  color: var(--dark-grey);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  height: 100%;
}

.productList {
  margin: 50px 0;
}

.productListList {
  max-height: calc(100vh - 500px);
  overflow-x: hidden;
  overflow-y: auto;
}

.productName {
  font-size: 18px;
  margin: 15px 0;
}

.info {
  font-weight: 300;
  font-size: 18px;
}

.bottom {
  margin-top: auto;
  margin-bottom: 50px;
  border-top: 2px dashed #bdbdbd;
}

.cartIcon {
  display: block;
  margin: 0 auto 70px;
  width: 62px;
  height: 62px;
  fill: var(--light-grey);
}

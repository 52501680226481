.list {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
}

.noItems {
  padding: 0 20%;
}

@media (min-width: 1600px) {
  .list {
    grid-template-columns: auto auto auto;
  }
}

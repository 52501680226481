@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: url("../assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: url("../assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto";
  font-weight: 300;
  src: url("../assets/fonts/Roboto-Light.ttf");
}

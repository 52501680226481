.app {
  display: grid;
  grid-template-columns: auto 25%;
  background-color: var(--background);
  min-height: 100vh;
}

.aside {
  background-color: var(--light-grey-2);
  position: sticky;
  top: 0;
  height: 100vh;
  padding: 0 30px;
}
